export function prepareFormDataTrueFalseOptions (data, keyStringArray) {
  const prepareFormData = {
    ...data
  }

  for (const key of keyStringArray) {
    if (key in prepareFormData) {
      prepareFormData[key] = prepareFormData[key]?.value
    }
  }

  return prepareFormData
}
