<template>
  <button class="btn-refresh btn--menu btn-reset">
    <span class="btn__icon-wrap">
      <span class="icon-refresh">
        <svgicon
          icon="button/refresh"
          width="19"
          height="20"
        />
      </span>
    </span>
  </button>
</template>

<script>
export default {
  name: 'ButtonRefresh'
}
</script>
