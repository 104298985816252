<template>
  <div>
    <div class="d-flex ai-c jc-sb mb-40">
      <div class="d-flex gap-xxs">
        <VButton
          size="menu"
          icon="filter"
          color="primary-sm"
          :active="isFilter"
          @click.native="toggleFilter"
        />

        <VInput
          v-model="searchValue"
          type="search"
          placeholder="Поиск по номеру заказа и артикулу"
          width="460px"
        />

        <ButtonRefresh
          :disabled="isLoadingList"
          @click.native="switchLoadList"
        />

        <VCheckbox
          v-if="overdueCount"
          v-model="filterByDateStatus.overdue"
          class="filter__checkbox ml-20"
          label="Просроченные заказы"
          counter-color="red"
          :counter="overdueCount"
          :isDisabled="isLoadingList"
          @click.native="onClickCountRedFlag"
        />

        <VCheckbox
          v-if="deadlineIsComingCount"
          v-model="filterByDateStatus.deadline_is_coming"
          class="filter__checkbox ml-20"
          label="Срок подходит"
          counter-color="orange"
          :counter="deadlineIsComingCount"
          :isDisabled="isLoadingList"
          @click.native="onClickCountYellowFlag"
        />
      </div>

      <router-link v-if="permissionCreateOrder" :to="{ name: 'OrderCreate' }">
        <VButton
          class="as-c"
          text="Создать заказ"
          icon="create"
          size="sm"
          color="primary-sm"
        />
      </router-link>
    </div>

    <!--  TODO FILTER  -->
    <transition name="fade">
      <VForm
        v-if="isFilter"
        :value="filterFormData"
        @input="SET_FILTER_FORMDATA"
        class="form-filter mb-20"
        ref="formDataOrderRef"
        @request="filterSubmit"
      >
        <div class="grid-filter mb-30">
          <VInputDate
            label="По дате поступления заказа"
            ref="dateFilterRef"
            :value="modelData"
            @value-changed="setFilterDateOrderCreate"
          />

          <VInput label="По названию компании:" name="client_name" />

          <VSelect
            label="Сортировка по:"
            name="sort"
            :options="filterSortList"
          />

          <VSelect
            label="По ответственному:"
            name="responsible"
            :options="responsibleList"
          />

          <VSelect
            label="По типу заказа:"
            name="order_type"
            multiple
            :options="propertiesAll.order_type"
          />

          <transition name="fade">
            <VSelect
              v-if="!isOrderList"
              label="По этапу:"
              name="stage"
              multiple
              :options="propertiesAll.status"
            />
          </transition>

          <VSelect
            label="Цех производства:"
            name="workshop"
            multiple
            :options="workshopOptions"
            @change="setWorkshopSelected"
          />

          <VSelect
            label="По способу навивки:"
            name="winding_method"
            multiple
            :options="windingMethodOptionsFiltered"
          />

          <VSelect
            label="По типу пружин:"
            name="spring_type"
            multiple
            :options="propertiesAll.product_type"
          />

          <VSelect
            label="По диаметру проволоки:"
            name="d_wires"
            searchable
            multiple
            :options="dWireAll"
          />

          <VSelect
            label="По самовывозу:"
            name="is_pickup"
            placeholder="Все заказы"
            :options="pickupOptions"
          />

          <VSelect
            label="По готовности документов:"
            name="documents_are_ready"
            placeholder="Все заказы"
            :options="documentOptions"
          />

          <VSelect
            label="По рекламации:"
            name="reclamation"
            :options="reclamationOptions"
            multiple
          />
          <VCheckbox
            v-model="isDeleted"
            name="is_deleted"
            v-if="isAdmin && isOrderList"
            label="Показать удаленные"
            type="switch"
          />
        </div>

        <div class="buttons">
          <VButton text="Применить" type="submit" color="primary" />

          <VButton text="Сбросить" @click.native="onClickResetBtn" />
        </div>
      </VForm>
    </transition>

    <NavTabsSwiper :tabs-list="tabs" class-name-nav-item="" />

    <router-view />
  </div>
</template>

<script>
import VInputDate from '@/components/Form/VInputDate'
import ButtonRefresh from '@/components/simple/button/ButtonRefresh'
import VForm from '@/components/Form/VForm'
import VButton from '@/components/simple/button/VButton'
import VInput from '@/components/Form/Vinput/VInput'
import VSelect from '@/components/Form/VSelect'
import NavTabsSwiper from '@/shared/ui/tabs/NavTabsSwiper.vue'
import VCheckbox from '@/components/Form/VCheckbox.vue'

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { setCurrentRouteQueryPage } from '@/utils/router'
import { transformDateForSend } from '@/utils/form/date'
import { prepareFormDataIdArray } from '@/utils/form/prepareFormDataIdArray'
import { mixinIsFilter } from '@/mixins/mixinIsFilter'
import { mapTrueFalseToOptions } from '@/utils/map/mapTrueFalseToOptions'
import { prepareFormDataTrueFalseOptions } from '@/utils/form/prepareFormDataTrueFalseOptions'
import getValuesArray from '@/utils/array/getValuesArray'
import { mixinWorkshopAndWindingMethodOptions } from '@/mixins/views/mixinWorkshopAndWindingMethodOptions'
import { toItemWithIdByIdx } from '@/shared/lib/utils/map/handbook'

const pickupOptions = [
  'отобразить только самовывоз',
  'скрыть самовывоз'
].map(mapTrueFalseToOptions)

const documentOptions = [
  'подготовлены',
  'не подготовлены'
].map(mapTrueFalseToOptions)

const reclamationOptions = [
  {
    name: 'Внутренняя',
    key: 'is_inner_reclamation',
    value: true
  },
  {
    name: 'Внешняя',
    key: 'is_outer_reclamation',
    value: true
  }
].map(toItemWithIdByIdx)

export default {
  components: {
    VCheckbox,
    NavTabsSwiper,
    VInputDate,
    ButtonRefresh,
    VForm,
    VButton,
    VInput,
    VSelect
  },
  mixins: [mixinIsFilter, mixinWorkshopAndWindingMethodOptions],
  data: () => ({
    isDeleted: false,
    filterSortList: [
      { id: 2, name: 'по дате отгрузки', slug: 'date_finished' },
      { id: 3, name: 'по срокам', slug: 'timings.days' }
    ]
  }),

  computed: {
    ...mapState('registerPage', {
      filterFormData: 'filterFormData',
      dateOrderCreate: 'dateOrderCreate',
      search: 'search',
      queryParams: 'queryParams'
    }),
    ...mapState('users', {
      responsibleList: 'all'
    }),
    ...mapState('tabsOrderList', ['tabs']),
    ...mapState('order', {
      filterByDateStatus: 'filterByDateStatus',
      isLoadingOrderList: 'isLoadingOrderList'
    }),
    ...mapGetters('order', {
      overdueCount: 'overdueCount',
      deadlineIsComingCount: 'deadlineIsComingCount'
    }),
    ...mapState('consignment', ['isLoadingConsignmentList']),
    ...mapGetters({
      permissionCreateOrder: 'permission/permissionCreateOrder',
      propertiesAll: 'records/propertiesAll',
      dWireAll: 'records/dWireAll',
      isAdmin: 'permission/isAdmin'
    }),

    pickupOptions: () => pickupOptions,
    documentOptions: () => documentOptions,
    reclamationOptions: () => reclamationOptions,

    searchValue: {
      get () {
        return this.search
      },
      set (value) {
        this.SET_SEARCH(value)
        this.switchLoadList()
      }
    },

    modelData: {
      get () {
        return this.dateOrderCreate
      },
      set (data) {
        this.SET_DATE_ORDER_CREATE(data.value)
      }
    },

    isOrderList () {
      return this.$route.name.indexOf('Order') !== -1
    },

    isLoadingList () {
      return this.isLoadingOrderList || this.isLoadingConsignmentList
    }
  },

  created () {
    if (this.filterFormData.workshop) {
      this.setWorkshopSelected(this.filterFormData.workshop)
    }
    this.SET_QUERY_PARAMS()
  },

  methods: {
    ...mapMutations('registerPage', {
      SET_FILTER_FORMDATA: 'SET_FILTER_FORMDATA',
      SET_SEARCH: 'SET_SEARCH',
      RESET_SEARCH: 'RESET_SEARCH',
      SET_DATE_ORDER_CREATE: 'SET_DATE_ORDER_CREATE',
      SET_QUERY_PARAMS: 'SET_QUERY_PARAMS'
    }),
    ...mapActions({
      fetchOrderListData: 'order/fetchOrderListData',
      fetchConsignmentListData: 'consignment/fetchConsignmentListData'
    }),
    ...mapMutations('order', {
      TOGGLE_FILTER_OVERDUE: 'TOGGLE_FILTER_OVERDUE',
      TOGGLE_FILTER_DEADLINE_IS_COMING: 'TOGGLE_FILTER_DEADLINE_IS_COMING',
      RESET_FILTER_BY_DATE_STATUS: 'RESET_FILTER_BY_DATE_STATUS'
    }),

    async filterSubmit (data) {
      const prepareFormData = prepareFormDataTrueFalseOptions(data, [
        'is_pickup',
        'documents_are_ready'
      ])
      let formData = {
        ...transformDateForSend(this.dateOrderCreate),
        ...prepareFormData,
        sort: prepareFormData.sort?.slug || '-id',
        user_id: prepareFormData.responsible?.id || null,
        responsible_id: prepareFormData.responsible?.id || null,
        is_inner_reclamation: this.is_inner_reclamation || null
      }
      delete formData.responsible

      const keyListForIdArray = [
        'workshop',
        'order_type',
        'winding_method',
        'spring_type'
      ]

      formData = prepareFormDataIdArray(formData, keyListForIdArray)
      formData.d_wires = formData.d_wires
        ? getValuesArray(formData.d_wires, 'name')
        : null

      if (formData.reclamation) {
        for (const option of formData.reclamation) {
          formData[option.key] = option.value
        }
        delete formData.reclamation
      }

      if (formData.is_deleted === false) {
        delete formData.is_deleted
      }

      this.$store.commit('consignment/SET_FILTER', formData)
      this.$store.commit('order/SET_FILTER', formData)

      await this.switchLoadList()
    },

    setFilterDateOrderCreate (data) {
      this.SET_DATE_ORDER_CREATE(data.value)
    },

    async onClickResetBtn () {
      this.RESET_SEARCH()
      this.resetFilter()
      await this.switchLoadList()
    },

    async onClickCountRedFlag () {
      this.TOGGLE_FILTER_OVERDUE()
      await this.switchLoadList()
    },

    async onClickCountYellowFlag () {
      this.TOGGLE_FILTER_DEADLINE_IS_COMING()
      await this.switchLoadList()
    },

    async switchLoadList () {
      if (+this.$route.query.page !== 1) {
        return setCurrentRouteQueryPage(1)
      }

      if (this.isOrderList) {
        await this.fetchOrderListData()
        return
      }
      await this.fetchConsignmentListData()
    },

    resetFilter () {
      this.resetRefs()
      this.RESET_FILTER_BY_DATE_STATUS()
      this.$store.commit('order/SET_FILTER', {})
      this.$store.commit('consignment/SET_FILTER', {})
      this.SET_QUERY_PARAMS({})
    },

    resetRefs () {
      this.$refs.formDataOrderRef.clear()
      this.$refs.dateFilterRef.$refs.dateRef._flatpickr.clear()
    }
  }
}
</script>
