export const mixinWorkshopAndWindingMethodOptions = {
  data: () => ({
    workshopSelected: null
  }),
  computed: {
    workshopOptions () {
      return this.$store.state.records.properties.workshop
    },

    windingMethodOptionsFiltered () {
      if (!this.workshopSelected) return this.windingMethodOptions

      return this.windingMethodOptions.filter(windingMethod => {
        if (!windingMethod.workshops?.length) return false

        for (const workshop of this.workshopSelected) {
          for (const windingMethodWorkshop of windingMethod.workshops) {
            if (workshop.id === windingMethodWorkshop.id) return true
          }
        }
        return false
      })
    },

    windingMethodOptions () {
      return this.$store.state.records.properties.winding_method
    }
  },
  methods: {
    setWorkshopSelected (value) {
      this.workshopSelected = value
    }
  }
}
