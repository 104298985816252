export function toHandbook (name, idx) {
  return {
    name,
    id: idx + 1
  }
}

export function toItemWithIdByIdx (item, idx) {
  return {
    ...item,
    id: idx + 1
  }
}

export function getNameOrDash (item) {
  return item?.name || '-'
}
